import defaultProps from 'element-plus/es/components/table/src/table/defaults'

export default {
  key: {
    type: String || Number,
    default: '0'
  },
  /** Table columns config */
  columns: {
    type: Array,
    default: []
  },
  /** Table loading */
  loading: {
    type: Boolean,
    default: false
  },
  /** Table loading config */
  loadingConfig: {
    type: Object,
    default: () => {}
  },
  alignWhole: {
    type: String,
    default: 'left'
  },
  headerAlign: {
    type: String,
    default: ''
  },
  showOverflowTooltip: {
    type: Boolean,
    default: false
  },
  rowHoverBgColor: {
    type: String,
    default: ''
  },
  pagination: {
    type: Object,
    default: {
      total: 0,
      pageSize: 5,
      align: 'right',
      background: false,
      pageSizes: [10, 20, 30, 50],
      layout: 'total, sizes, prev, pager, next, jumper'
    }
  },
  paginationSmall: {
    type: Boolean,
    default: true
  },
  ...defaultProps
}
