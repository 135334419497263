<template>
    <router-view></router-view>
</template>
<script>
import { useStore } from 'vuex'
import {onBeforeMount,onMounted,getCurrentInstance} from "vue"
export default {
    setup() {
        // const store = useStore()
        // const beforeMount = onBeforeMount(()=>{
            
        // })
        // const mounted = onMounted(()=>{
            
        // })
        return {}
    },
}
</script>
<style lang="scss" >

.table-form-content {
  widows: 100%;
}

</style>