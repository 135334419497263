<template>
  <div class="qw_table">
    <transition name="el-zoom-in-top">
      <layout-search
        v-show="searchVis"
        :options="searchOption"
        :dict-data="dialogParams.dictData"
        @data="searchData"
      />
    </transition>
    <div class="table_top">
      <div class="table_btn_left">
        <!--        <el-button v-if="btnConfigs.store && btnConfigs.store.show"-->
        <!--                   :disabled="btnConfigs.store && btnConfigs.store.disabled" type="primary" :icon="Plus"-->
        <!--                   @click="openAddDialog">{{ $t('btn.add') }}-->
        <!--        </el-button>-->
        <slot
          name="table_topleft_hook"
          :dialog-params="dialogParams"
          :list-count="listCount.data"
          :multiple-selection="multipleSelection"
        />
        <el-button
          v-if="btnConfigs.search && btnConfigs.search.show"
          type="primary"
          plain
          :icon="Search"
          @click="searchOpen"
        >
          {{ $t('btn.search2') }}
        </el-button>
      </div>
      <div class="table_btn_right">
        <slot
          name="table_topright_before_hook"
          v-bind="{ openEditDialog, openAddDialog, closeDialog }"
        />
        <el-button
          v-if="btnConfigs.store && btnConfigs.store.show"
          :disabled="btnConfigs.store && btnConfigs.store.disabled"
          type="primary"
          :icon="Plus"
          @click="openAddDialog"
        >
          {{ $t('btn.add') }}
        </el-button>
        <el-button
          v-if="btnConfigs.import && btnConfigs.import.show"
          :icon="Upload"
        >
          {{
            $t('btn.import')
          }}
        </el-button>
        <el-button
          v-if="btnConfigs.export && btnConfigs.export.show"
          :icon="Download"
          @click="excelExport"
        >
          {{ $t('btn.export') }}
        </el-button>
        <el-button
          v-if="btnConfigs.destroy && btnConfigs.destroy.show"
          type="danger"
          :icon="Delete"
          @click="deleteData"
        >
          {{ $t('btn.del') }}
        </el-button>
        <slot
          name="table_topright_hook"
          v-bind="{ openEditDialog, openAddDialog, closeDialog }"
        />
      </div>
    </div>
    <slot name="table_top_hook" />
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :show-header="showHeader"
      :data="listData.listData || []"
      border
      style="width: 100%"
      :height="height"
      :lazy="tableCfg.lazy"
      :row-key="columnId"
      :load="tableCfg.lazy ? lazyLoad : null"
      @row-click="rowClick"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        v-if="selection"
        type="selection"
        width="55"
      />
      <el-table-column
        v-if="expand"
        type="expand"
        width="55"
      >
        <template #default="scope">
          <slot
            name="expand"
            :scope-data="scope.row"
            :index="scope.$index"
          />
        </template>
      </el-table-column>
      <el-table-column
        v-for="(v, k) in options"
        :key="k"
        :label="v.label"
        :type="v.type"
        :align="align"
        :width="v.value == 'created_at' ? (v.width ? v.width || null : '165px') : v.width || null"
        :show-overflow-tooltip="v.overflowTooltip || false"
        :fixed="v.fixed || null"
        :resizable="v.resizable"
      >
        <template #default="scope">
          <!-- 默认展示文字 -->
          <span v-if="v.type == 'text' || v.type == undefined">{{
            scope.row[v.value] || '-'
          }}</span>
          <span v-if="v.type == 'money'"><em
            v-if="parseFloat(scope.row[v.value]) >= 0"
            class="moneycss paymoney"
          >+{{ scope.row[v.value] || '-' }}</em><em
            v-if="parseFloat(scope.row[v.value]) < 0"
            class="moneycss"
          >{{
            scope.row[v.value] || '-'
          }}</em></span>
          <q-tags
            v-if="v.type == 'tags'"
            :value="scope.row[v.value]"
          />
          <span v-if="v.type == 'tags_array'">
            <q-tags
              v-for="(arrItem, arrKey) in scope.row[v.value]"
              :key="arrKey"
              :styles="{ marginRight: '8px' }"
              :value="arrItem"
            />
          </span>
          <span v-if="v.type == 'icon_tags'"><i :class="'icon_tags fa ' + scope.row[v.value]" />
            <q-tags :value="scope.row[v.value]" />
          </span>
          <span v-if="v.type == 'dict'">{{
            dictFind(v.value, scope.row[v.value], v.labelName || 'label', v.valueName || 'value')
          }}</span>
          <q-tags
            v-if="v.type == 'dict_tags'"
            :tag_type="v.tag_type || false"
            :value="
              dictFind(v.value, scope.row[v.value], v.labelName || 'label', v.valueName || 'value')
            "
          />
          <el-image
            v-if="v.type == 'avatar' || v.type == 'image'"
            :preview-teleported="true"
            :style="
              v.style || {
                width: '50px',
                height: '50px',
                borderRadius: '4px',
                textAlign: 'center',
                lineHeight: '65px',
                background: R.isEmpty(scope.row[v.value]) ? '#f2f2f2' : 'null',
                display: 'block'
              }
            "
            :fit="v.fit || 'fill'"
            :hide-on-click-modal="true"
            :src="scope.row[v.value]"
            lazy
            :preview-src-list="v.perView ? [scope.row[v.value]] : null"
          >
            <template #error>
              <el-icon
                :color="v.errColor || '#888'"
                :size="v.errorPicSize || 26"
              >
                <Picture />
              </el-icon>
            </template>
          </el-image>
          <div
            v-if="v.type == 'custom'"
            class="custom_column"
          >
            <slot
              :name="v.value"
              :scope-data="scope.row"
              :index="scope.$index"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="handleHide"
        label="操作"
        :width="handleWidth"
        fixed="right"
      >
        <template #default="scope">
          <slot name="table_column_button">
            <div class="table_col_handle">
              <el-button
                v-if="btnConfigs.show && btnConfigs.show.show"
                :title="$t('btn.view')"
                :icon="View"
                @click="showData(scope.row)"
              />
              <el-button
                v-if="btnConfigs.update && btnConfigs.update.show"
                :title="$t('btn.edit')"
                type="primary"
                :icon="Edit"
                @click="openEditDialog(scope.row)"
              />
              <slot
                name="table_handleright_hook"
                :rows="scope.row"
              />
              <el-button
                v-if="btnConfigs.deletes && btnConfigs.deletes.show"
                type="danger"
                :icon="Delete"
                @click="deleteRowData(scope.row[columnId])"
              />
            </div>
          </slot>
        </template>
      </el-table-column>
    </el-table>
    <div
      v-if="pagination"
      class="tabel_pagination"
    >
      <!-- <span class="demonstration">Change page size</span> -->
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :page-size="listParams.per_page"
        :page-sizes="[1, 30, 100, 200, 300, 400]"
        :page-count="listParams.last_page"
        :current-page="listParams.current_page"
        :total="listParams.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!-- 添加dialog -->
    <el-dialog
      ref="addDialog"
      v-model="addVis"
      :destroy-on-close="dialogParams.destroyOnClose"
      custom-class="table_dialog_class"
      :title="$t('btn.add')"
      :width="dialogParams.width"
    >
      <slot
        name="table_add_hook"
        :dialog-params="dialogParams"
      >
        <el-form
          v-if="dialogParams.add && dialogParams.add.column.length > 0 && addVis"
          ref="addForm"
          label-position="right"
          :rules="dialogParams.rules || null"
          :model="formData.add"
          :label-width="dialogParams.labelWidth"
          :fullscreen="dialogParams.fullscreen"
        >
          <el-row :gutter="20">
            <el-col
              v-for="(v, k) in dialogParams.add.column"
              :key="k"
              :span="v.span || dialogParams.span"
            >
              <div
                v-if="v.show"
                class="table-form-content"
                style="width: 100%"
              >
                <el-form-item
                  :label="v.label"
                  :prop="v.value"
                >
                  <q-input
                    v-model:formData="formData.add[v.value]"
                    :params="v"
                    :dict-data="dialogParams.dictData || []"
                  >
                    <span>{{ v.suffixName }}</span>
                  </q-input>
                </el-form-item>
              </div>
            </el-col>
            <!-- <el-col :span="12"><div class="table-form-content"></div></el-col> -->
          </el-row>

          <!-- 按钮处理 -->
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item>
                <el-button
                  :loading="loading"
                  type="primary"
                  @click="storeData"
                >
                  {{
                    $t('btn.determine')
                  }}
                </el-button>
                <el-button @click="addVis = false">
                  {{ $t('btn.cancel') }}
                </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-empty v-else />
      </slot>
    </el-dialog>

    <!-- 编辑dialog -->
    <el-dialog
      ref="editDialog"
      v-model="editVis"
      :destroy-on-close="dialogParams.destroyOnClose"
      custom-class="table_dialog_class"
      :title="$t('btn.edit')"
      :width="dialogParams.width"
    >
      <slot
        name="table_edit_hook"
        :dialog-params="dialogParams"
        :form-data="formData"
      >
        <el-form
          v-if="dialogParams.edit && dialogParams.edit.column.length > 0 && editVis"
          ref="editForm"
          label-position="right"
          :rules="dialogParams.rules || null"
          :model="formData.edit"
          :label-width="dialogParams.labelWidth"
          :fullscreen="dialogParams.fullscreen"
        >
          <el-row :gutter="20">
            <el-col
              v-for="(v, k) in dialogParams.edit.column"
              v-show="v.show"
              :key="k"
              :span="v.span || dialogParams.span"
            >
              <div class="table-form-content">
                <el-form-item
                  :label="v.label"
                  :prop="v.value"
                >
                  <q-input
                    v-model:formData="formData.edit[v.value]"
                    :params="v"
                    :dict-data="dialogParams.dictData || []"
                    :disabled="v.disabled"
                  >
                    <span>{{ v.suffixName }}</span>
                  </q-input>
                </el-form-item>
              </div>
            </el-col>
            <!-- <el-col :span="12"><div class="table-form-content"></div></el-col> -->
          </el-row>

          <!-- 按钮处理 -->
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item>
                <el-button
                  :loading="loading"
                  type="primary"
                  @click="updateData"
                >
                  {{
                    $t('btn.determine')
                  }}
                </el-button>
                <el-button @click="editVis = false">
                  {{ $t('btn.cancel') }}
                </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-empty v-else />
      </slot>
    </el-dialog>

    <!-- 显示dialog -->
    <el-dialog
      ref="viewDialog"
      v-model="viewVis"
      destroy-on-close
      custom-class="table_dialog_class"
      :title="$t('btn.view')"
      :width="dialogParams.width"
    >
      <slot
        name="table_show_hook"
        :dialog-params="dialogParams"
        :form-data="formData"
      >
        <el-form
          v-if="dialogParams.view && dialogParams.view.column.length > 0"
          label-position="right"
          :label-width="dialogParams.labelWidth"
          :fullscreen="dialogParams.fullscreen"
        >
          <el-row :gutter="20">
            <el-col
              v-for="(v, k) in dialogParams.view.column"
              :key="k"
              :span="v.span || dialogParams.span"
            >
              <div class="table-form-content">
                <el-form-item
                  :label="v.label + ' : '"
                  :prop="v.value"
                >
                  <q-tags
                    v-if="
                      v.viewType == 'tag' ||
                        (v.viewType == undefined && v.type == undefined) ||
                        v.type == 'number' ||
                        v.type == 'datetime' ||
                        v.type == 'date' ||
                        v.type == 'cascader_lazy' ||
                        v.type == 'map'
                    "
                    :value="formData.view[v.value] || ''"
                    :suffix="v.suffixName"
                    :color="v.color || null"
                    :effect="v.effect || null"
                    :size="v.size || null"
                    :is-text="v.isText || false"
                  />
                  <span v-if="v.viewType == 'text'">{{ formData.view[v.value] }}</span>
                  <div
                    v-if="v.viewType == 'html'"
                    class="html_view"
                    v-html="editorSplit(formData.view[v.value])"
                  />
                  <span v-if="v.viewType == 'tags_array'">
                    <q-tags
                      v-for="(arrItem, arrKey) in formData.view[v.value]"
                      :key="arrKey"
                      :styles="{ marginRight: '8px' }"
                      :value="arrItem"
                    />
                  </span>
                  <span v-if="v.viewType == 'tags_array_more'">
                    <q-tags
                      v-for="(arrItem, arrKey) in dictFind(
                        v.value,
                        formData.view[v.value],
                        v.labelName || 'label',
                        v.valueName || 'value',
                        v.customFn
                      )"
                      :key="arrKey"
                      :styles="{ marginRight: '8px' }"
                      :value="arrItem"
                    />
                  </span>
                  <span v-if="v.type == 'dict' || v.viewType == 'dict'">{{
                    dictFind(
                      v.value,
                      formData.view[v.value],
                      v.labelName || 'label',
                      v.valueName || 'value'
                    )
                  }}</span>
                  <span v-if="v.viewType == 'array_more'">
                    <!-- <span>{{ getArr(v.value,formData.view[v.value]).join(',') }}</span> -->
                    <q-tags
                      v-for="(arrItem, arrKey) in getArr(v.value,formData.view[v.value])"
                      :key="arrKey"
                      tag_type="warning"
                      :styles="{ marginRight: '8px' }"
                      :value="arrItem"
                    />
                  </span>
                  <q-tags
                    v-if="v.type == 'dict_tags' || v.viewType == 'dict_tags'"
                    :tag_type="v.tag_type || false"
                    :value="
                      dictFind(
                        v.value,
                        formData.view[v.value],
                        v.labelName || 'label',
                        v.valueName || 'value',
                        v.customFn
                      )
                    "
                  />
                  <el-image
                    v-if="v.type == 'avatar' || v.type == 'image'"
                    :style="
                      v.style || {
                        width: '50px',
                        height: '50px',
                        borderRadius: '4px',
                        textAlign: 'center',
                        lineHeight: '65px',
                        background: R.isEmpty(formData.view[v.value]) ? '#f2f2f2' : 'null',
                        display: 'block'
                      }
                    "
                    :size="100"
                    :fit="v.fit || 'fill'"
                    :hide-on-click-modal="true"
                    :src="formData.view[v.value]"
                    lazy
                    :preview-src-list="v.perView ? [formData.view[v.value]] : null"
                  >
                    <template #error>
                      <el-icon
                        :color="v.errColor || '#888'"
                        :size="v.errorPicSize || 26"
                      >
                        <Picture />
                      </el-icon>
                    </template>
                  </el-image>
                  <div v-if="v.viewType == 'images'">
                    <el-image
                      v-for="(imgItem, imgKey) in formData.view[v.value]"
                      :key="imgKey"
                      class="images_item"
                      :style="
                        v.style || {
                          width: '50px',
                          marginRight: '10px',
                          height: '50px',
                          borderRadius: '4px',
                          textAlign: 'center',
                          lineHeight: '65px',
                          background: R.isEmpty(formData.view[v.value]) ? '#f2f2f2' : 'null',
                          display: 'inline-block'
                        }
                      "
                      :size="100"
                      :fit="v.fit || 'fill'"
                      :hide-on-click-modal="true"
                      :src="imgItem"
                      lazy
                      :preview-src-list="v.perView ? formData.view[v.value] : null"
                    >
                      <template #error>
                        <el-icon
                          :color="v.errColor || '#888'"
                          :size="v.errorPicSize || 26"
                        >
                          <Picture />
                        </el-icon>
                      </template>
                    </el-image>
                  </div>
                </el-form-item>
              </div>
            </el-col>
            <!-- <el-col :span="12"><div class="table-form-content"></div></el-col> -->
          </el-row>
        </el-form>
        <el-empty v-else />
      </slot>
      <slot
        name="table_show_bottom_hook"
        :dialog-params="dialogParams"
        :form-data="formData"
      />
    </el-dialog>
  </div>
</template>

<script>
import { reactive, ref, watch, provide, onMounted, getCurrentInstance, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import layoutSearch from '@/components/common/search.vue'
import { Plus, Edit, View, Delete, Download, Upload, Picture, Search } from '@element-plus/icons'
import qTags from '@/components/common/tags.vue'

export default {
  components: { layoutSearch, qTags, Picture },
  props: {
    // label , elabel , prop  显示字段
    searchOption: {
      type: Array,
      default: () => {
        return []
      }
    },
    height: {
      type: Number,
      default: 640
    },
    options: {
      type: Array,
      default: () => {
        return []
      }
    },
    customDelete: {
      type: Boolean,
      default: false
    },
    // tabl表数据
    tableData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // table配置
    tableCfg: {
      type: Object,
      default: () => {
        return {
          lazy: false,
          pid: 'id'
        }
      }
    },
    // 操作行宽度
    handleWidth: {
      type: Number,
      default: 130
    }, // 操作栏宽度
    // 操作列是否显示
    handleHide: {
      type: Boolean,
      default: true
    },
    btnConfig: {
      type: Object,
      default: () => {
        return {}
      }
    },
    page: {
      //是否分页
      type: Boolean,
      default: true
    },
    // 默认获取的行唯一值
    columnId: {
      type: String,
      default: 'id'
    },
    // Pagination 分页显示
    pagination: {
      type: Boolean,
      default: true
    },
    // 列表数据URI
    pageUrl: {
      type: String,
      default: ''
    },

    // 弹框Dialog参数控制
    dialogParam: {
      type: Object,
      default: () => {}
    },

    // 自定义formData
    cutomFormData: {
      type: Object,
      default: () => {}
    },

    // 搜索关键词和翻页数据
    params: {
      type: Object,
      default: () => {
        return {}
      }
    },
    limitType: {
      // 删除限制类型
      type: String,
      default: ''
    },
    customAdd: {
      // 自定义添加
      type: Boolean,
      default: false
    },
    expand: {
      type: Boolean,
      default: false
    },
    customAddRequest: {
      // 自定义添加请求
      type: Boolean,
      default: false
    },
    customUpdateRequest: {
      // 自定义编辑请求
      type: Boolean,
      default: false
    },
    customView: {
      // 自定义查看
      type: Boolean,
      default: false
    },
    selection: {
      type: Boolean,
      default: true
    },
    spanMethod: {
      type: Object,
      default: {}
    },
    lazyParams: {
      type: Object,
      default: {}
    },
    align: {
      type: String,
      default: 'left'
    },
    showHeader: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const { ctx, proxy } = getCurrentInstance()
    const route = useRoute()
    const store = useStore()
    const multipleSelection = reactive([])
    const searchVis = ref(false)
    const listCount = reactive({ data: {} })
    const listData = reactive({ listData: [] })
    const viewData = reactive({ data: {} })
    const addVis = ref(false) // 添加窗口状态
    const editVis = ref(false) // 添加窗口状态
    const viewVis = ref(false) // 查看窗口状态
    const loading = ref(false) // 按钮状态
    // 列表参数处理
    let queryParams = {
      per_page: 30, // 每页大小
      total: 0,
      last_page: 1,
      page: 1
    }
    queryParams = Object.assign(queryParams, props.params)
    const listParams = reactive(queryParams) // 分页还要搜索参数
    // 列表行的点击
    const rowClick = (row, column) => {
      emit('row-click', row, column)
    }
    // 默认按钮状态
    let btnConfig = {
      show: { show: true, url: '', disabled: false }, // 显示
      store: { show: true, url: '', disabled: false }, // 添加
      update: { show: true, url: '', disabled: false }, // 编辑
      destroy: { show: true, url: '', disabled: false }, // 删除
      deletes: { show: false, url: '', disabled: false }, // 删除单行
      search: { show: true, url: '', disabled: false }, // 删除单行
      export: { show: true, url: '', disabled: false },
      import: { show: false, url: '', disabled: false }
    }
    btnConfig = Object.assign(btnConfig, props.btnConfig)
    const btnConfigs = reactive(btnConfig)

    // 弹框默认参数
    let dialogParam = {
      width: '60%',
      labelWidth: '90px', // 字体宽度
      span: 12, // 默认宽度
      column: [], // 默认字段
      fullscreen: false, // 是否全屏
      rules: null,
      destroyOnClose: true,
      isPageDict: false,
      dict: [], // 字典链接 {name:"menus",url:'xxx.com'}
      dictData: {}, // 字典数据 {menus:[]}
      multipleSelection: () => {
        return multipleSelection.value
      },
      addOpenBefore: () => {}, // 打开添加之前执行
      editOpenBefore: () => {}, // 打开编辑之前执行
      editOpenAfter: () => {},
      closeDialog: () => {
        closeDialog()
      }, // 关闭所有弹框
      reloadData: () => {
        Object.assign(listParams, props.params)
        loadData() // 重加载列表数据
      },
      loadView: async (url = null) => {
        // 获取详情信息
        return await proxy.R.get(url != null ? url : pageUrl + '/' + row[props.columnId])
      },
      add: {
        column: [] // {label:'我的游戏',value:'name'},{label:'定位密钥',value:'name'},{label:'头像',value:'name'}
      },
      edit: {
        column: []
      },
      view: {
        column: []
      }
    }
    dialogParam = Object.assign(dialogParam, props.dialogParam)
    const dialogParams = reactive(dialogParam)
    // 字典处理
    const dictHandle = () => {
      if (dialogParam.dict.length <= 0) return
      dialogParam.dict.map(async (item) => {
        let dictResp = await proxy.R.get(item.url)
        dialogParams.dictData[item.name] = dialogParam.isPageDict ? dictResp['data'] : dictResp
        if (item.addSelect) dialogParams.dictData[item.name].unshift(item.addSelect)
      })
    }
    // 根据键值字典名称获取对应label 最多三层
    const dictFind = (dictName, value, labelName = 'label', valueName = 'value', customFn) => {
      let dictVal = '-'
      if (dialogParams.dictData[dictName] && dialogParams.dictData[dictName].length > 0) {
        if (customFn) {
          dictVal = customFn(dialogParams.dictData[dictName], value, formData.view)
        } else {
          dialogParams.dictData[dictName].map((item) => {
            if (item[valueName] == value) dictVal = item[labelName]
            if (item.children && item.children.length > 0) {
              item.children.map((itemS) => {
                if (itemS[valueName] == value) dictVal = itemS[labelName]
                if (itemS.children && itemS.children.length > 0) {
                  itemS.children.map((itemT) => {
                    if (itemT[valueName] == value) dictVal = itemT[labelName]
                  })
                }
              })
            }
          })
        }
      }
      return dictVal
    }
    const getArr = (dictName,ids) => {
      var arr = []
      if(ids){
        dialogParams.dictData[dictName].forEach(item=>{
          ids.forEach((v)=>{
            if(v == item.value){
              arr.push(item.text)
            }
          })
        })
      }  
      return arr
    }
    dictHandle()

    const editorSplit = (val) => {
      // debugger
      if (!proxy.R.isEmpty(val)) {
        const splitStr = '##xiaoxiong##'
        return val.split(splitStr)[0]
      }
    }

    let cutomFormData = { add: {}, edit: {}, view: {} }
    cutomFormData = Object.assign(cutomFormData, props.cutomFormData)
    const formData = reactive(cutomFormData)

    // const editForm = reactive({})

    // 如果没有传请求接口则默认取该路由链接为接口
    // let propRefs = toRefs(props) // 解构props
    let pageUrl = props.pageUrl // 组成链接
    if (proxy.R.isEmpty(props.pageUrl)) pageUrl = route.path // 后台请求列表数据的后台接口url跟页面保持一致

    // 选择数据
    const handleSelectionChange = (e) => {
      emit('selection-change', e)
      let idArr = []
      e.map((item) => {
        idArr.push(item[props.columnId])
      })
      multipleSelection.value = idArr
    }
    // 修改页面大小
    const handleSizeChange = (e) => {
      listParams.per_page = e
      loadData()
    }
    // 修改页面内容
    const handleCurrentChange = (e) => {
      listParams.page = e
      if (listParams.per_page) loadData()
    }

    // 获取列表信息
    const loadData = async () => {
      if (props.tableData.data) {
        if (props.tableData.data.length == 0) return
        listData.listData = props.tableData.data
        listCount.data = props.tableData.count
        listData.listData = props.tableData.data
        listParams.total = parseInt(props.tableData.total)
        listParams.per_page = parseInt(props.tableData.per_page)
        listParams.last_page = parseInt(props.tableData.last_page)
        listParams.page = parseInt(props.tableData.current_page)
        return
      }
      if (!props.tableCfg.lazy) {
        loading.value = true
        // debugger 立即获取列表信息
        const allData = await proxy.R.get(pageUrl, listParams)
        if (
          allData.headers &&
          allData.request &&
          typeof allData.data == 'string' &&
          allData.data.indexOf('<!doctype html>') > -1
        )
          return
        listCount.data = allData.count
        listData.listData = props.page ? allData.data : allData // 分页或不分页取数据的方式不同
        listParams.total = parseInt(allData.total)
        listParams.per_page = parseInt(allData.per_page)
        listParams.last_page = parseInt(allData.last_page)
        listParams.page = parseInt(allData.current_page)
      } else {
        loading.value = true
        // debugger // lazy懒加载 / 这个应该是获取父子关系树的分支
        let childrenData = await getChildrenData(0)
        allTreeData = childrenData // 保存首次加载的根节点数据
        console.log(allTreeData, 'allTreeDataallTreeDataallTreeData')
        listData.listData = childrenData
        listParams.total = childrenData.length
      }
      loading.value = false

      // 重新加载字典
      dictHandle()
    }

    // 值发生改变
    watch(
      () => props.tableData,
      (e) => {
        // console.log(e)
        loadData()
      }
    )
    watch(
      () => formData.edit.store_type,
      (val, old) => {
        dialogParams.dictData['children_type'] = dialogParams.dictData['children_type_' + val];
        if(old){
          formData.edit.children_type = [];
        }
        dialogParams.edit.column.forEach((item) => {
          if (item.value === 'class_id') {
            if (val !== 1) {
              item.show = false
            } else {
              item.show = true
            }
          }else if(item.value === 'children_type') {
            if ([3,4].includes(Number(val))) {
              item.show = true
              dialogParams.rules.children_type = [{ required: true, message: '不能为空'}]
            } else {
              dialogParams.rules.children_type = []
              item.show = false
            }
          }else {
            item.show = true
          }
        })
      },
      {
        // 首次渲染组件就触发一次
        immediate: true,
        // 开启深度监听,对象里面的数据如果发生变化也会被侦听到
        // 如果监听的数据是一个比较长的表达式，那么需要用一个函数的方式
        // 但是写成函数形式之后，里层的数据变化不到，所以需要添加deep选项
        deep: true
      }
    )
    watch(
      () => formData.add.store_type,
      (val) => {
        dialogParams.dictData['children_type'] = dialogParams.dictData['children_type_' + val];
        formData.add.children_type = [];
        // 监听新增的值
        dialogParams.add.column.forEach((item) => {
          if (item.value === 'class_id') {
            if (val !== 1) {
              item.show = false
            } else {
              item.show = true
            }
          }else if(item.value === 'children_type') {
            if ([3,4].includes(Number(val))) {
              item.show = true
            } else {
              item.show = false
            }
          } else {
            item.show = true
          }
        })
      },
      {
        // 首次渲染组件就触发一次
        immediate: true,
        // 开启深度监听,对象里面的数据如果发生变化也会被侦听到
        // 如果监听的数据是一个比较长的表达式，那么需要用一个函数的方式
        // 但是写成函数形式之后，里层的数据变化不到，所以需要添加deep选项
        deep: true
      }
    )
    const getChildrenData = async (pid = 0) => {
      let childrenData = await proxy.R.get(pageUrl, {
        isChildren: true,
        pid: pid,
        ...props.lazyParams
      })
      childrenData.forEach((item) => {
        if (item.has_children != null) {
          item.hasChildren = true
        }
      })
      return childrenData
    }
    let allTreeData = reactive([]) // 保存展开的节点树上的所有节点
    // 懒加载
    let treeData = reactive({})
    let resolveFn = reactive({})
    const lazyLoad = async (tree, treeNode, resolve) => {
      resolveFn = resolve
      treeData = tree
      let childRaw = await getChildrenData(tree[props.tableCfg.pid || 'id'])
      allTreeData = [...allTreeData, ...childRaw]
      resolve(childRaw)
    }

    // 打开添加编辑弹框
    const openAddDialog = async (v) => {
      await dictHandle()
      if (props.customAdd) {
        // 自定义添加弹窗
        emit('add')
      } else {
        dialogParams.addOpenBefore(v)
        addVis.value = true
      }
    }
    const clearData = () => {
      console.log(formData.add, 'formData.addformData.addformData.add')
      for (let key in formData.add) {
        formData.add[key] = ''
      }
    }
    // 添加数据
    const storeData = () => {
      let keys = []
      for (let key in formData.add) {
        keys.push(key)
      }
      dialogParam.add.column.map((item) => {
        let newAddData = {}
        if (_.indexOf(keys, item.value) == -1) {
          newAddData[item.value] = ''
          Object.assign(formData.add, newAddData)
        }
      })
      // console.log(dialogParam.add.column, 'dialogParams.edit.columndialogParams.edit.column');

      proxy.$refs.addForm.validate((valid) => {
        // 验证失败直接断点
        if (!valid) return false
        loading.value = true
        try {
          if (props.customAddRequest) {
            emit('add-request', formData.add, treeData, resolveFn)
          } else {
            proxy.R.post(pageUrl, formData.add)
              .then((res) => {
                if (!res.code || !res.data || !res.msg) {
                  loadData()
                  addVis.value = false
                  clearData()
                  loading.value = false
                  proxy.$message.success(proxy.$t('msg.success'))
                }
              })
              .catch((err) => {
                console.log(err)
              })
              .finally(() => {
                loading.value = false
              })
          }
        } catch (error) {
          loading.value = false
        }
      })
    }

    // 查看数据
    const showData = async (row) => {
      if (props.customView) {
        emit('view', row)
      } else {
        viewVis.value = true
        formData.view.id = row[props.columnId]
        const newViewData = await proxy.R.get(pageUrl + '/' + row[props.columnId])
        formData.view = newViewData
      }
    }
    const selectionItem = (row, select) => {
      proxy.$refs.multipleTable.toggleRowSelection(row, select)
    }
    // 打开编辑弹框
    const openEditDialog = async (row) => {
      await dictHandle()
      dialogParams.editOpenBefore()
      formData.edit = {}
      formData.edit = await proxy.R.get(pageUrl + '/' + row[props.columnId])
      editVis.value = true
      dialogParams.edit.column.map((item, key) => {
        // placeholder关联字段
        if (item.relation)
          dialogParams.edit.column[key]['placeholder'] = formData.edit[item.relation]
      })
      dialogParams.editOpenAfter()
    }
    // 编辑数据
    const updateData = () => {
      proxy.$refs.editForm.validate((valid) => {
        // 验证失败直接断点
        if (!valid) return false
        loading.value = true

        // 找出相对应的数据存入，如不存在的不处理
        let newViewData = {}
        dialogParams.edit.column.map((item) => {
          newViewData[item.value] = formData.edit[item.value] || ''
        })
        formData.edit[props.columnId] = formData.edit[props.columnId]

        try {
          if (props.customUpdateRequest) {
            emit('update-request', formData.edit)
          } else {
            proxy.R.put(pageUrl + '/' + formData.edit[props.columnId], formData.edit)
              .then((res) => {
                loading.value = false
                if (!res.code || !res.data || !res.msg) {
                  // proxy.$message.success(proxy.$t('msg.success'))
                  editVis.value = false
                  loadData()
                }
              })
              .catch((err) => {
                console.log(err)
              })
              .finally(() => {
                loading.value = false
              })
          }
        } catch (error) {
          loading.value = false
        }
      })
    }

    // 关闭dialog
    const closeDialog = () => {
      addVis.value = false
      editVis.value = false
      viewVis.value = false
    }
    const orgDeleteLimit = (valuelist) => {
      // 机构删除限制函数
      let list = listData.listData.filter((item) => {
        let value = valuelist.find((el) => {
          return el === item.id
        })
        if (value) return item
      })
      let passList = list.filter((item) => {
        // 筛选出有选择通过审核的数据
        return item.store_verify === 4
      })
      if (passList.length > 0) {
        proxy.$message.error('不可删除已通过审核的数据') // 警告提示
        return false
      } else {
        return true
      }
    }
    const goodsClassDleletLimit = (valuelist) => {
      // 商品分类，如果有子节点的数据不能被删除
      let list = allTreeData.filter((item) => {
        let value = valuelist.find((el) => {
          return el === item.id
        })
        if (value) return item
      })
      let passList = list.filter((item) => {
        // 筛选出有选择通过审核的数据
        return item.has_children
      })
      if (passList.length > 0) {
        proxy.$message.error('节点下有数据， 无法删除') // 警告提示
        return false
      } else {
        return true
      }
    }
    let limitDelete = computed(() => {
      let type = props.limitType
      console.log(type, 'typetypetypetypetype')
      return type === 'org'
        ? orgDeleteLimit
        : type === 'goods'
        ? goodsClassDleletLimit
        : function () {
            return true
          }
    })
    // 删除数据
    const deleteData = async () => {
      // console.log(ctx.$refs,proxy.$refs)
      if (props.customDelete) {
        emit('delete', listData.listData, multipleSelection.value) // 自定义删除，传出列表数据和当前选中的值
      } else {
        if (!multipleSelection.value || multipleSelection.value.length <= 0)
          return proxy.$message.error(proxy.$t('msg.selectErr'))
        let ids = multipleSelection.value.join(',')
        let gate = limitDelete.value(multipleSelection.value) // 删除限制
        // let delParams = {}
        // delParams[props.columnId] = ids
        gate &&
          ElementPlus.ElMessageBox.confirm(proxy.$t('table.delmsg'), proxy.$t('table.deltit'), {
            confirmButtonText: proxy.$t('btn.determine'),
            cancelButtonText: proxy.$t('btn.cancel'),
            type: 'warning'
            // center: true,
          })
            .then(async () => {
              let res = await proxy.R.deletes(pageUrl + '/' + ids, {})
              if (res) {
                loadData()
                return proxy.$message.success(proxy.$t('msg.success')) // ElementPlus.ElMessage.error
              }
            })
            .catch(() => {})
      }
    }
    // 删除一条数据
    const deleteRowData = async (id) => {
      let gate = limitDelete.value([id]) // 删除限制
      gate &&
        ElementPlus.ElMessageBox.confirm(proxy.$t('table.delmsg'), proxy.$t('table.deltit'), {
          confirmButtonText: proxy.$t('btn.determine'),
          cancelButtonText: proxy.$t('btn.cancel'),
          type: 'warning'
          // center: true,
        })
          .then(async () => {
            let res = await proxy.R.deletes(pageUrl + '/' + id, {})
            lazyLoad(treeData, {}, resolveFn)
            loadData()
            if (props.tableData) emit('refresh')
            if (res.code === 200) {
              return proxy.$message.success(proxy.$t('msg.success')) // ElementPlus.ElMessage.error
            }
          })
          .catch(() => {})
    }

    // 搜索
    const searchData = (e) => {
      Object.assign(listParams, e)
      loadData()
    }
    const searchOpen = () => {
      if (props.searchOption && props.searchOption.length <= 0)
        return proxy.$message.error('wait setting.')
      searchVis.value = !searchVis.value
    }

    // excel导出
    const excelExport = () => {
      // 显示字段
      if (props.options.length < 0) return proxy.$message.error(proxy.$t('msg.selectErr'))
      let excelExportData = []
      let headerData = []
      listData.listData.map((item) => {
        let colItem = []
        headerData = []
        // 判断是否有选择数据 没有则取全部数据
        if (!multipleSelection.value || multipleSelection.value.length <= 0) {
          props.options.map((itemOption) => {
            colItem.push(item[itemOption.value] || '')
            headerData.push(itemOption.label)
          })
          excelExportData.push(colItem)
        } else {
          let ids = multipleSelection.value.join(',').split(',')
          if (_.indexOf(ids, item[props.columnId] + '') > -1) {
            props.options.map((itemOption) => {
              colItem.push(item[itemOption.value] || '')
              headerData.push(itemOption.label)
            })
            excelExportData.push(colItem)
          }
        }
      })
      excelExportData.unshift(headerData)
      const ws = XLSX.utils.aoa_to_sheet(excelExportData)
      const wb = XLSX.utils.book_new()
      // XLSX.utils.sheet_add_aoa(ws, [[1,2], [2,3], [3,4]], {origin: "A2"});
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
      XLSX.writeFile(wb, (store.state.load.routeMenuName || 'table') + '.xlsx')
    }

    // excel导入
    const excelImport = () => {}

    // 初始化获取数据
    loadData()

    // 把这个对象返回回去
    emit('tableView', dialogParams)

    return {
      handleSelectionChange,
      handleSizeChange,
      handleCurrentChange,
      showData,
      viewData,
      storeData,
      updateData,
      formData,
      searchOpen,
      deleteData,
      deleteRowData,
      clearData,
      multipleSelection,
      searchVis,
      addVis,
      editVis,
      viewVis,
      openEditDialog,
      openAddDialog,
      closeDialog,
      searchData,
      loading,
      loadData,
      selectionItem,
      listCount,
      listData,
      listParams,
      lazyLoad,
      dictFind,
      getArr,
      editorSplit,
      btnConfigs,
      dialogParams,
      excelExport,
      excelImport,
      Plus,
      Edit,
      View,
      Delete,
      Download,
      Upload,
      Picture,
      Search,
      rowClick
      // routeMenuName:computed(()=>store.state.load.routeMenuName),
    }
  }
}
</script>
<style lang="scss" scoped>
.qw_table {
  height: 100%;

  .moneycss {
    color: rgb(66, 185, 131);

    &.paymoney {
      color: red;
    }
  }

  .table_top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between; /* 横向中间自动空间 */
    .table_btn_left {
    }

    .table_btn_right {
    }
  }

  .tabel_pagination {
    margin-top: 20px;
  }

  .icon_tags {
    margin-right: 10px;
  }

  .table_col_handle {
    text-align: center;
  }

  .html_view {
    // background: #f8f8f8;
    border: 1px solid #efefef;
    padding: 5px;
  }
}
</style>
<style lang="scss">
.table_dialog_class {
  .el-dialog__header {
    border-bottom: 1px solid #efefef;
  }
}
.el-table__inner-wrapper {
  overflow: auto !important;
}
::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #efefef;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #fff;
}
</style>
