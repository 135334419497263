import { defineComponent } from "vue";

const props = {
  render: {
    type: Function,
    require: true,
  },
  params: {
    type: Object
  }
};

export default defineComponent({
  name: "Renderer",
  props,
  setup(_) {
    return () => <>{_.render(_.params)}</>;
  }
});
