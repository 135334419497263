import lodash from 'lodash'
import { createApp } from 'vue'
import store from '@/stores'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import i18n from '@/locales'
import router from '@/plugins/router'
import R from '@/plugins/http'
import App from '@/views/App.vue'
import '@/plugins/css/style.css'
import qInput from '@/components/common/input.vue'
import PureTable from '@/components/pureTable'
import zhCn from 'element-plus/es/locale/lang/zh-cn' // element中文配置
window._ = lodash

// const localeElementPlus = require(`@/locales/element/${R.getLocalesName()}`)
// console.log(R.getLocalesName(), 'R.getLocalesName()')
// const localeElementPlus = import.meta.globEager(`./locales/element/${R.getLocalesName()}.js`)
let elementInit = { size: 'default', zIndex: 3000, locale: zhCn }

router.afterEach(() => {
  window.scrollTo(0, 0)
})

// 挂载初始化
const app = createApp(App)
app.component('q-input', qInput)
app.use(i18n).use(store).use(router).use(ElementPlus, elementInit).use(PureTable)
app.config.globalProperties.R = R
app.mount('#app')
