import {_import} from '@/plugins/config'

export const baseRoute = [
    // 基本页面
    {
        path: '/',
        component: () => import('@/components/home-new/homePage.vue') //首页改版
    },
    // { path: '/bid', component: () => import('@/components/home-new/bid.vue') },
    {
        path: '/bid', component: () => import('@/components/home-new/ztb.vue'), name: 'bid', children: [
            {
                path: ':id', component: () => import('@/components/home-new/ztb-info.vue')
            }
        ]
    },
    {path: '/business', component: () => import('@/components/home-new/business.vue')},
    // { path: '/ztb', component: () => import('@/components/home-new/ztb.vue') },
    {path: '/cooperation', component: () => import('@/components/home-new/cooperation.vue')},
    {path: '/bigData', component: () => import('@/components/home-new/bigData.vue')},
    {path: '/news', component: () => import('@/components/home-new/news.vue')},
    {path: '/finance', component: () => import('@/components/home-new/finance.vue')},
    {path: '/supplier/home', component: () => import('@/components/home-new/supplier.vue')},
    {path: '/buyer/home', component: () => import('@/components/home-new/buyer.vue')},
    {path: '/trader/home', component: () => import('@/components/home-new/trader.vue')},
    {path: '/newsList', component: () => import('@/components/home-new/newsList.vue')},
    {path: '/newsDetail', component: () => import('@/components/home-new/newsDetail.vue')},
    {path: '/supplyChain', component: () => import('@/components/home-new/supplyChain.vue')},
    {path: '/supplyChainNew', component: () => import('@/components/home-new/supplyChainNew.vue')},
    {path: '/invoice', component: () => import('@/components/home-new/invoice.vue')},
    {path: '/sms-visualization', component: () => import('@/components/home-new/sms-visualization.vue')},
    {path: '/Admin/login', component: _import('Admin/login')},
    // { path: '/Seller/login', component: _import('Seller/login') },
    // { path: '/Supplier/login', component: _import('Supply/login') },
    // { path: '/Buyer/login', component: _import('Buyer/login') },
    // { path: '/Trader/login', component: _import('Trade/login') },
    {path: '/web/login', component: () => import('@/layout/login.vue')},
    // {path: '/noreferer/jump', component: () => import('@/components/common/noreferer.vue')}
    // {path: "/:catchAll(.*)",name: '404',component: _import('Error/404')},
]
