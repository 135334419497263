import {routeUriType} from '@/plugins/config'
import store from '@/stores'
import R from '@/plugins/http'
import router from '../../../plugins/router'
// initial state
const state = {
    userData: [],
    loginData: [] // {isLogin:false,isLoad:false,tokenName:'',loginName:''}
}

// getters
const getters = {}

// actions
const actions = {
    async updateLogin({state}, loginData = []) {
    },
    async loginAfter(
        {state},
        {
            access_token = '',
            refresh_token = '',
            routeUriIndex = 2,
            path = '/Admin/login',
            isTo = false
        }
    ) {
    },
    // 获取用户信息
    async getUser({state}, routeUriIndex = 2) {
        let userName = 'user'
        if (state.loginData[routeUriIndex] && state.loginData[routeUriIndex].tokenName !== '')
            userName = state.loginData[routeUriIndex].tokenName + '_user'
        return JSON.parse(sessionStorage.getItem(userName))
    },
    // 获取用户信息存储
    async setUser() {
        // await loginAfter();
    },
    async setLoginData(context, data) {
        state.loginData = data
        return state.loginData
    },
    async setUserData({state}, data) {
        state.userData = data
        return state.userData
    },
    async setLoginDataVal(context, {key = 'isLogin', value = false, index = 2}) {
        if (state.loginData[index]) state.loginData[index][key] = value
        return state.loginData[index]
    },
    async getLoginData() {
        return state.loginData
    },
    async getUserData() {
        return state.userData
    },
    // 从服务端获取
    async editUserSer({state}, params) {
        let routeUriIndex = store.state.load.routeUriIndex
        let postPath =
            state.loginData[routeUriIndex] && state.loginData[routeUriIndex].loginName != ''
                ? state.loginData[routeUriIndex].loginName
                : '/'
        let provider =
            state.loginData[routeUriIndex] && state.loginData[routeUriIndex].tokenName == 'admin'
                ? state.loginData[routeUriIndex].tokenName
                : 'user'
        const user = await R.post(postPath + 'auth/edit', {provider: provider + 's', ...params})
        return user
    },

    // 从服务端获取
    async getUserSer({state}) {
        let userName = 'user'
        let routeUriIndex = store.state.load.routeUriIndex
        if (state.loginData[routeUriIndex] && state.loginData[routeUriIndex].tokenName !== '')
            userName = state.loginData[routeUriIndex].tokenName + '_user'
        let postPath =
            state.loginData[routeUriIndex] && state.loginData[routeUriIndex].loginName != ''
                ? state.loginData[routeUriIndex].loginName
                : '/'
        let provider =
            state.loginData[routeUriIndex] && state.loginData[routeUriIndex].tokenName == 'admin'
                ? state.loginData[routeUriIndex].tokenName
                : 'user'
        let user
        if (
            postPath.indexOf('supplier') > -1 ||
            postPath.indexOf('trader') > -1 ||
            postPath.indexOf('buyer') > -1
        ) {
            user = await R.get(postPath + 'auth/user', {provider: provider + 's'})
        } else {
            user = await R.post(postPath + 'auth/info', {provider: provider + 's'})
        }
        sessionStorage.setItem(userName, JSON.stringify(user))
        return user
    }
}

// mutations
const mutations = {
    async loginAfter(
        state,
        {
            access_token = '',
            refresh_token = '',
            routeUriIndex = 2,
            path = '/Admin/login',
            isTo = false,
            store_type = '',
            authority = [],
            second = 0
        }
    ) {
        if (store_type === 'temporary') {
            sessionStorage.setItem('temporary_user', JSON.stringify(authority))
            sessionStorage.setItem('temporary_token', access_token)
            const timestamp = second <= -1 ? -1 :  new Date().getTime() + second * 1000
            sessionStorage.setItem('temporary_timestamp', String(timestamp))
            await store.dispatch('login/setLoginDataVal', {
                key: 'isLogin',
                value: true,
                index: routeUriIndex
            })
            return
        }
        let tokenName = 'token'
        let userName = 'user'
        let loginData = []
        if (!state.loginData[routeUriIndex]) {
            const setRouteData = await store.dispatch('load/setRouteIndex', path)
            loginData = setRouteData.loginData
            routeUriIndex = setRouteData.routeUriIndex
        } else {
            loginData = state.loginData
        }
        // console.log(loginData)
        // loginData[routeUriIndex].isLogin = true
        // state.userData[routeUriIndex] = {}
        if (loginData[routeUriIndex] && loginData[routeUriIndex].tokenName !== '')
            tokenName = loginData[routeUriIndex].tokenName + '_token'
        if (loginData[routeUriIndex] && loginData[routeUriIndex].tokenName !== '')
            userName = loginData[routeUriIndex].tokenName + '_user'
        if (loginData[routeUriIndex]) loginData[routeUriIndex].isLogin = true
        sessionStorage.setItem(tokenName, access_token)
        // 用户信息添加到
        let postPath =
            loginData[routeUriIndex] && loginData[routeUriIndex].loginName != ''
                ? loginData[routeUriIndex].loginName
                : '/'
        let provider =
            loginData[routeUriIndex] && loginData[routeUriIndex].tokenName == 'admin'
                ? loginData[routeUriIndex].tokenName
                : 'user'
        let user
        if (routeUriIndex === 2) {
            user = await R.get('/supplier/auth/user') // 供应商的信息
        } else if (routeUriIndex === 3) {
            user = await R.get('/buyer/auth/user') // 采购商的信息
        } else if (routeUriIndex === 4) {
            user = await R.get('/trader/auth/user') // 贸易商的信息
        } else {
            user = await R.post(postPath + 'auth/info', {provider: provider + 's'})
        }
        if (loginData) sessionStorage.setItem(userName, JSON.stringify(user))
        state.userData[routeUriIndex] = user
        await store.dispatch('login/setLoginDataVal', {
            key: 'isLogin',
            value: true,
            index: routeUriIndex
        })
        if (isTo) router.push(user.defaultUrl || '/')
    },

    async setUser(state, user) {
        let routeUriIndex = store.state.load.routeUriIndex
        let userName = 'user'
        if (state.loginData[routeUriIndex] && state.loginData[routeUriIndex].tokenName !== '')
            userName = state.loginData[routeUriIndex].tokenName + '_user'
        sessionStorage.setItem(userName, JSON.stringify(user))
    },

    async getUser(state) {
        let routeUriIndex = store.state.load.routeUriIndex
        let userName = 'user'
        if (state.loginData[routeUriIndex] && state.loginData[routeUriIndex].tokenName !== '')
            userName = state.loginData[routeUriIndex].tokenName + '_user'
        return JSON.parse(sessionStorage.getItem(userName))
    },

    // 退出
    async logout(state) {
        let routeUriIndex = store.state.load.routeUriIndex
        let provider =
            state.loginData[routeUriIndex] && state.loginData[routeUriIndex].tokenName == 'admin'
                ? state.loginData[routeUriIndex].tokenName
                : 'user'
        R.post('/logout', {provider: provider + 's'})
            .then((item) => {
            })
            .finally(() => {
                // 跳转
                let routeUriIndex = store.state.load.routeUriIndex
                let userName = 'user'
                let tokenName = 'token'
                if (state.loginData[routeUriIndex] && state.loginData[routeUriIndex].tokenName !== '')
                    tokenName = state.loginData[routeUriIndex].tokenName + '_token'
                if (state.loginData[routeUriIndex] && state.loginData[routeUriIndex].tokenName !== '')
                    userName = state.loginData[routeUriIndex].tokenName + '_user'
                if (state.loginData[routeUriIndex]) state.loginData[routeUriIndex].isLogin = false
                sessionStorage.removeItem(tokenName)
                sessionStorage.removeItem(userName)
                let postPath =
                    state.loginData[routeUriIndex] && state.loginData[routeUriIndex].loginName != ''
                        ? state.loginData[routeUriIndex].loginName
                        : '/'
                if (['/supplier/', '/trader/', '/buyer/', '/Admin/'].includes(postPath)) {
                    postPath = '/web/'
                }
                router.push(postPath + 'login')
            })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
