<template>
  <div class="app-wrapper">
    <div class="sidebar-container" :style="{ width: menu.isCollapse ? '50px' : '210px' }">
      <div class="sidebar-logo-container">
        <a class="router-link-active sidebar-logo-link">
          <img class="logo-img" src="/src/js/assets/Admin/system_logo.png" alt="供应链钱多多" />
          <span class="sidebar-title">供应链钱多多</span>
        </a>
      </div>
      <el-scrollbar class="scrollbar-wrapper">
        <div class="menu-wrapper">
          <el-menu
            :default-active="route.path"
            router
            class="el-menu-vertical"
            :collapse="menu.isCollapse"
            :unique-opened="true"
            :collapse-transition="true"
            @select="handleSelect"
          >
            <template v-for="(item, index) in menu.currentMenu" :key="index">
              <el-sub-menu
                v-if="item.children && item.children.length"
                :index="item.apis || String(item.id)"
              >
                <template #title>
                  <el-icon v-if="item.icon != ''"><i :class="'fa ' + item.icon"></i></el-icon>
                  <span>{{ item.name }}</span>
                </template>
                <template v-for="(childrenItem, i) in item.children" :key="i">
                  <el-sub-menu
                    v-if="childrenItem.children && childrenItem.children.length"
                    :index="childrenItem.apis || String(childrenItem.id)"
                    v-show="menu.hiddenMenu(childrenItem.name)"
                  >
                    <template #title>
                      <el-icon v-if="childrenItem.icon != ''"
                        ><i :class="'fa ' + childrenItem.icon"></i
                      ></el-icon>
                      <span>{{ childrenItem.name }}</span>
                    </template>
                    <template v-for="(value, idx) in childrenItem.children" :key="idx">
                      <el-menu-item
                        :index="value.apis || String(value.id)"
                        v-show="menu.hiddenMenu(value.name)"
                      >
                        <template #title>
                          <el-icon v-if="value.icon != ''">
                            <i :class="'fa ' + value.icon"></i>
                          </el-icon>
                          <span>{{ value.name }}</span>
                        </template>
                      </el-menu-item>
                    </template>
                  </el-sub-menu>
                  <el-menu-item
                    v-else
                    :index="childrenItem.apis || String(childrenItem.id)"
                    v-show="menu.hiddenMenu(childrenItem.name)"
                  >
                    <template #title>
                      <el-icon v-if="childrenItem.icon != ''">
                        <i :class="'fa ' + childrenItem.icon"></i>
                      </el-icon>
                      <span>{{ childrenItem.name }}</span>
                    </template>
                  </el-menu-item>
                </template>
              </el-sub-menu>
              <el-menu-item
                v-else
                :index="item.apis || String(item.id)"
                v-show="menu.hiddenMenu(item.name)"
              >
                <el-icon v-if="item.icon != ''"><i :class="'fa ' + item.icon"></i></el-icon>
                <template #title>
                  <span>{{ item.name }}</span>
                </template>
              </el-menu-item>
            </template>
          </el-menu>
        </div>
      </el-scrollbar>
      <div class="fold-container">
        <el-tooltip
          effect="light"
          :content="menu.isCollapse ? '点击展开' : '点击折叠'"
          placement="right-start"
        >
          <el-icon :size="20" color="#fff" @click="handleMenuToggleCollapse">
            <fold v-if="!menu.isCollapse" />
            <Expand v-if="menu.isCollapse" />
          </el-icon>
        </el-tooltip>
      </div>
    </div>
    <div class="main-container" :style="{ marginLeft: menu.isCollapse ? '50px' : '210px' }">
      <div class="app-header">
        <div>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item v-for="value in menu.breadcrumbList" :key="value.key">
              {{ value.item.name }}
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>

        <el-dropdown popper-class="custom_dropdown">
          <div class="user-info">
            <el-avatar :size="24" :src="'circleUrl'">
              <img :src="users.avatar || ''" />
            </el-avatar>
            <span class="el-dropdown-link">
              <span>{{ users.nickname || '-' }}</span>
            </span>
          </div>

          <template #dropdown>
            <el-dropdown-menu class="dropdown-header">
              <el-dropdown-item @click="openUserForm"
                ><i class="fa fa-user" />用户中心</el-dropdown-item
              >
              <el-dropdown-item @click="$store.commit('login/logout')"
                ><i class="fa fa-sign-out" />退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <div class="main-content--wrapper">
        <el-scrollbar class="main-content--scrollbar">
          <div style="padding: 20px">
            <slot name="main_view" v-if="!hideMian">
              <router-view></router-view>
            </slot>
            <slot name="main_view_sub" v-else>
              <router-view></router-view>
            </slot>
            <!-- <router-view></router-view> -->
          </div>
        </el-scrollbar>
      </div>
    </div>
    <el-dialog
      destroy-on-close
      class="base_dialog_class"
      v-model="userInfo.showEditDialog"
      title="用户中心"
      width="30%"
    >
      <el-form
        ref="USEREDITFORM"
        label-position="right"
        :rules="userInfo.userRules"
        :model="userInfo.userInfoForm"
        :label-width="80"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="昵称" prop="nickname">
              <el-input v-model="userInfo.userInfoForm.nickname" style="width: 85%" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="密码" prop="password">
              <el-input v-model="userInfo.userInfoForm.password" style="width: 85%" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="头像" prop="avatar">
              <el-upload
                class="avatar-uploader"
                :action="'/api' + userInfo.uploadPath + 'uploads'"
                :show-file-list="false"
                :headers="{ Authorization: userInfo.Token }"
                :data="{ option: { width: 150, height: 150 }, name: 'avatar' }"
                :on-success="userInfo.handleAvatarSuccess"
              >
                <img
                  v-if="userInfo.userInfoForm.avatar"
                  style="width: 100%; height: 100%"
                  :src="userInfo.userInfoForm.avatar"
                  class="avatar"
                />
                <el-icon v-else class="avatar-uploader-icon">
                  <Plus />
                </el-icon>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 按钮处理 -->
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item>
              <el-button
                :loading="userInfo.submitLoading"
                type="primary"
                @click="userInfo.updateUser"
                >确定</el-button
              >
              <el-button @click="userInfo.showEditDialog = false">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>
<script setup>
import { reactive, computed, onMounted, ref, defineProps } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { Fold, Expand, Plus } from '@element-plus/icons'
import { getToken, getUploadPath } from '@/plugins/config'
import { hiddenRouteOptions } from '@/utils/utils'
const props = defineProps({
  hideMian: {
    type: Boolean,
    default: false
  }
})
const store = useStore()
const route = useRoute()

const USEREDITFORM = ref()
// 菜单信息
const menu = reactive({
  menuData: store.state.load.menuData,
  routeUriIndex: store.state.load.routeUriIndex,
  nowRoutePoint: store.state.load.nowRoutePoint,
  nowRoutePointIds: store.state.load.nowRoutePointIds,
  moduleIndex: store.state.load.moduleIndex,
  routeMenuName: store.state.load.routeMenuName,
  isCollapse: false,
  currentMenu: computed(() => {
    return store.state.load.menuData[store.state.load.routeUriIndex]
  }),
  breadcrumbList: computed(() => {
    return store.state.load.nowRoutePoint.reduce((p, c) => {
      p.unshift(c)
      return p
    }, [])
  }),
  hiddenMenu: computed(() => {
    return (val) => {
      let index = hiddenRouteOptions.findIndex((item) => {
        return val === item
      })
      return index >= 0 ? false : true
    }
  })
})
const users = reactive({})
// 用户信息编辑弹窗
const userInfo = reactive({
  showEditDialog: false,
  userInfoForm: {
    nickname: '',
    password: '',
    avatar: ''
  },
  userRules: reactive({
    nickname: [{ required: true, message: '请输入用户昵称' }]
  }),
  submitLoading: false,
  Token: getToken(),
  uploadPath: getUploadPath(),
  // 头像上传
  handleAvatarSuccess: (e) => {
    if (e.code != 200) return ElementPlus.ElMessage.error(e.msg)
    userInfo.userInfoForm.avatar = e.data
  },
  updateUser: async () => {
    USEREDITFORM.value.validate(async (valid) => {
      if (!valid) return false
      userInfo.submitLoading = true
      let userEditForm = {
        nickname: userInfo.userInfoForm.nickname,
        password: userInfo.userInfoForm.password || ''
      }
      if (userInfo.userInfoForm.avatar) userEditForm.avatar = userInfo.userInfoForm.avatar
      try {
        const servUser = await store.dispatch('login/editUserSer', userEditForm)
        if (!servUser.code) {
          let servUser2 = await store.dispatch('login/getUserSer')
          Object.assign(users, servUser2)
          Object.assign(userInfo.userInfoForm, servUser2)
          ElementPlus.ElMessage.success('修改成功')
        } else {
          ElementPlus.ElMessage.error(servUser.msg)
        }
        userInfo.showEditDialog = false
        userInfo.submitLoading = false
      } catch (e) {
        userInfo.showEditDialog = false
        userInfo.submitLoading = false
      }
    })
  }
})
/**
 * 展开收起侧边导航栏
 */
function handleMenuToggleCollapse() {
  menu.isCollapse = !menu.isCollapse
}

// 打开用户资料编辑弹窗
async function openUserForm() {
  userInfo.showEditDialog = true
  const servUser = await store.dispatch('login/getUserSer')
  Object.assign(userInfo.userInfoForm, servUser)
  Object.assign(users, userInfo.userInfoForm)
}

function handleSelect(index, indexPath, item, routeResult) {
  console.log(123, index, indexPath, item, routeResult)
}

onMounted(async () => {
  let user = await store.dispatch('load/getUser')
  Object.assign(users, user)
})
</script>
<style scoped lang="scss">
.app-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  .sidebar-container {
    width: 210px;
    height: 100%;
    position: fixed;
    font-size: 0;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1001;
    overflow: hidden;
    box-shadow: 0 0 1px #888;
    transition: width linear 0.3s;
    .sidebar-logo-container {
      background-color: #001529;
      width: 100%;
      height: 48px;
      overflow: hidden;
      position: relative;
      .sidebar-logo-link {
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding-left: 10px;
        width: 100%;
        .logo-img {
          width: 32px;
          height: 32px;
        }
        .sidebar-title {
          color: #fff;
          height: 32px;
          line-height: 32px;
          margin: 2px 0 0 12px;
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 18px;
          font-weight: 600;
        }
      }
    }
    .scrollbar-wrapper {
      height: calc(100% - 48px - 40px);
      background-color: #001529;
      :deep(.el-scrollbar__wrap) {
        overflow-x: hidden;
      }
      .menu-wrapper {
        font-size: 16px;
        padding: 5px;
        :deep(.el-menu) {
          background-color: transparent !important;
          border-right: none;
          &.el-menu--collapse {
            width: 50px;
            .el-sub-menu {
              padding: 0;
              &.is-active {
                &::before {
                  position: absolute;
                  top: 10px;
                  bottom: 10px;
                  left: 0;
                  width: 2px;
                  content: '';
                  clear: both;
                  transition: all 0.3s ease-in-out;
                  transform: translateY(0);
                  background-color: #1890ff;
                }
              }
              //   border-left: 2px solid #f6da4d;
            }
            .el-sub-menu__title {
              width: 50px !important;
              overflow: hidden;
              padding: 0 0 0 6px !important;
              margin: 0;
            }
          }
          .el-sub-menu__title {
            color: rgba(254, 254, 254, 0.65);
            height: 42px;
            &:hover {
              background-color: transparent;
              color: #fff !important;
            }
          }
          .el-menu-item.is-active {
            color: #fff !important;
            background-color: #1890ff;
            border-radius: 6px;
            &:hover {
              background-color: #1890ff;
            }
          }
          .el-menu-item {
            color: rgba(254, 254, 254, 0.65);
            height: 42px;
            &:hover {
              background-color: transparent;
              color: #fff !important;
            }
          }
          .el-sub-menu.is-active {
            > .el-sub-menu__title {
              color: #fff !important;
            }
          }
        }
      }
    }
    .fold-container {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 40px;
      line-height: 40px;
      box-shadow: 0 0 6px -2px #fff;
      background-color: #001529;
      padding-left: 10px;
      display: flex;
      align-items: center;
      &:hover {
        cursor: pointer;
      }
    }
    .el-icon {
      font-size: 16px;
    }
  }
  .main-container {
    height: 100%;
    margin-left: 210px;
    position: relative;
    background: #f4f4f4;
    transition: margin-left linear 0.3s;
    .app-header {
      width: 100%;
      height: 48px;
      background-color: #fff;
      box-shadow: 0 0 2px #ddd;
      line-height: 48px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      .user-info {
        display: inline-flex;
        align-items: center;
        height: 48px;
        justify-content: center;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.8509803922);
        padding: 0 10px;
        outline: none;
        &:hover {
          background-color: #f6f6f6;
        }
        .el-dropdown-link {
          padding-left: 10px;
          font-size: 14px;
        }
      }
    }
    .sub-header {
      height: 38px;
      background-color: #fff;
      box-shadow: 0 0 2px #403d3d;
      width: 100%;
    }
    .main-content--wrapper {
      height: calc(100% - 48px);
    }
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 78px;
  height: 78px;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  text-align: center;
}

.avatar-uploader-icon svg {
  // margin-top: 26px; /* (178px - 28px) / 2 - 1px */
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
<style>
* {
  box-sizing: border-box;
}
</style>
